<template>
    <div class='layout-dashboard'>
        <div class='grid'>
            <div class='col-12 md:col-4'>
                <div class='card widget-overview-box widget-overview-box-1'>
                    <span class='overview-title'>
                        月度{{ siteType }}排放总量（立方）
					</span>

                    <div class='flex justify-content-between'>
                        <div class='overview-detail flex justify-content-between'>
                            <div v-if='loadingObj.overviewLoading'>
                                <div class='flex justify-content-center align-items-center'>
                                    <Skeleton width='5rem' height='2.4rem' class='mt-2'></Skeleton>
                                    <Skeleton width='7rem' height='2.4rem' class='mt-2 ml-2'></Skeleton>
                                </div>
                            </div>
                            <div v-if='!loadingObj.overviewLoading'>
                                <div :class='getBadgeColor($store.getters.countAnalyze.monthChangeRate)'
                                     class='overview-badge flex justify-content-center align-items-center'>
                                    <i class='pi'
                                       :class="$store.getters.countAnalyze.monthChangeRate<=0?'pi-arrow-down':'pi-arrow-up'"></i>
                                    <span>{{ abs($store.getters.countAnalyze.monthChangeRate).toFixed(1) }}%</span>
                                </div>
                            </div>
                            <div class='overview-text mt-1' v-if='!loadingObj.overviewLoading'>
                                {{ $store.getters.countAnalyze.currentMonthCount.toFixed(0) }}
                            </div>
                        </div>
                    </div>
                    <img src='layout/images/dashboard/rate.svg'>
                </div>
            </div>

            <div class='col-12 md:col-4'>
                <div class='card widget-overview-box widget-overview-box-2'>
					<span class='overview-title'>
                        季度{{ siteType }}排放总量（立方）
					</span>
                    <div class='flex justify-content-between'>
                        <div class='overview-detail flex justify-content-between'>
                            <Skeleton v-if='loadingObj.overviewLoading' width='5rem' height='2.4rem'
                                      class='mt-2'></Skeleton>
                            <div v-if='!loadingObj.overviewLoading'
                                 :class='getBadgeColor($store.getters.countAnalyze.quarterChangeRate)'
                                 class='overview-badge flex justify-content-center align-items-center'>
                                <i class='pi'
                                   :class="$store.getters.countAnalyze.quarterChangeRate<=0?'pi-arrow-down':'pi-arrow-up'"></i>
                                <span>{{ abs($store.getters.countAnalyze.quarterChangeRate).toFixed(1) }}%</span>
                            </div>
                            <Skeleton v-if='loadingObj.overviewLoading' width='7rem' height='2.4rem'
                                      class='mt-2 ml-2'></Skeleton>
                            <div v-if='!loadingObj.overviewLoading' class='overview-text mt-1'>
                                {{ $store.getters.countAnalyze.currentQuarterCount.toFixed(0) }}
                            </div>
                        </div>
                    </div>
                    <img src='layout/images/dashboard/value.svg'>
                </div>
            </div>
            <div class='col-12 md:col-4'>
                <div class='card widget-overview-box widget-overview-box-3'>
					<span class='overview-title'>
                        年度{{ siteType }}排放总量（立方）
					</span>
                    <div class='flex justify-content-between'>
                        <div class='overview-detail flex justify-content-between'>
                            <Skeleton v-if='loadingObj.overviewLoading' width='5rem' height='2.4rem'
                                      class='mt-2'></Skeleton>
                            <div v-if='!loadingObj.overviewLoading'
                                 :class='getBadgeColor($store.getters.countAnalyze.yearChangeRate)'
                                 class='overview-badge flex justify-content-center align-items-center'>
                                <i class='pi'
                                   :class="$store.getters.countAnalyze.quarterChangeRate<=0?'pi-arrow-down':'pi-arrow-up'"></i>
                                <span>{{ abs($store.getters.countAnalyze.yearChangeRate).toFixed(1) }}%</span>
                            </div>
                            <Skeleton v-if='loadingObj.overviewLoading' width='7rem' height='2.4rem'
                                      class='mt-2 ml-2'></Skeleton>
                            <div v-if='!loadingObj.overviewLoading' class='overview-text mt-1'>
                                {{ $store.getters.countAnalyze.currentYearCount.toFixed(0) }}
                            </div>
                        </div>
                    </div>
                    <img src='layout/images/dashboard/quantity.svg'>
                </div>
            </div>

            <div class='col-12 flex flex-row justify-content-between'>
                <div class='col-6 card' style='padding-left:0;margin-right: 2px'>
                    <div class='' ref='rtdTable'>
                        <DataTable :value='$store.getters.rtdList' responsiveLayout='scroll'
                                   class='p-datatable-lg'
                                   selectionMode='single' dataKey='id' :metaKeySelection='false'>
                            <Column field='name' header='因子' class='text-lg'>
                                <template #body='slotProps'>
                                    <Skeleton v-if='loadingObj.rtdTable' height='1.5rem' />
                                    <div v-if='!loadingObj.rtdTable' :class='stateClass(slotProps.data.state)'>
                                        {{ slotProps.data.name }}
                                    </div>
                                </template>
                            </Column>
                            <Column field='rtd'
                                    :header="'测量值(' + $store.getters.rtdUpdateDateTime.substring(12,20)+')'">
                                <template #body='slotProps'>
                                    <Skeleton v-if='loadingObj.rtdTable' height='1.5rem' />
                                    <div v-if='!loadingObj.rtdTable' :class='stateClass(slotProps.data.state)'>
                                        {{ slotProps.data.value }}
                                    </div>
                                </template>
                            </Column>
                            <Column field='state' header='状态'>
                                <template #body='slotProps'>
                                    <Skeleton v-if='loadingObj.rtdTable' height='1.5rem' />
                                    <div v-if='!loadingObj.rtdTable' :class='stateClass(slotProps.data.state)'>
                                        {{ slotProps.data.state }}
                                    </div>
                                </template>
                            </Column>
                            <Column field='range' header='限值'>
                                <template #body='slotProps'>
                                    <Skeleton v-if='loadingObj.rtdTable' height='1.5rem' />
                                    <div v-if='!loadingObj.rtdTable'>
                                        {{ slotProps.data.range }}
                                    </div>
                                </template>
                            </Column>
                            <Column field='unit' header='单位'>
                                <template #body='slotProps'>
                                    <Skeleton v-if='loadingObj.rtdTable' height='1.5rem' />
                                    <div v-if='!loadingObj.rtdTable'>
                                        {{ slotProps.data.unit }}
                                    </div>
                                </template>
                            </Column>
                        </DataTable>
                    </div>
                </div>
                <div class='col-6 flex card' style='padding-right:0;margin-left: 2px;min-height: 400px'>
                    <v-chart class='chart' :option='$store.getters.rtdListChart' :theme='chartTheme'
                             :autoresize='true' />
                </div>
            </div>

            <div class='col-12 md:col-12 lg:col-12'>
                <div class='card widget-visitor-graph'>
                    <div class='card-header'>
                        <span>最近30天数据上传率</span>
                    </div>

                    <div class='graph-content grid'>
                        <div class='col-12 md:col-6'>
                            <Skeleton v-if='loadingObj.dataUploadRate' width='5rem' height='2.45rem' />
                            <h2 v-if='!loadingObj.dataUploadRate'>
                                {{ $store.getters.last30DaysDataUpPct.filter(d => d.value >= 100).length }}</h2>
                            <h6>达标天数</h6>
                            <p>按照市政要求，每日数据上传率不得低于100%. </p>
                        </div>
                        <div class='col-12 md:col-6'>
                            <Skeleton v-if='loadingObj.dataUploadRate' width='5rem' height='2.45rem' />
                            <h2 v-if='!loadingObj.dataUploadRate'>
                                {{ $store.getters.last30DaysDataUpPct.filter(d => !(d.value >= 100)).length }} </h2>
                            <h6>未达标天数</h6>
                            <p>数据长传率低于100%，注意检查是否需要补传. </p>
                        </div>
                    </div>

                    <Skeleton v-if='loadingObj.dataUploadRate' height='21.4rem' />
                    <div class='widget-target' v-if='!loadingObj.dataUploadRate'>
                        <Carousel ref='dataUploadAnalyzeWidget' :value='$store.getters.last30DaysDataUpPct'
                                  :numVisible='16'
                                  :numScroll='16' :circular='false'
                                  :responsiveOptions='carouselResponsiveOptions' :autoplayInterval='5000'>
                            <template #item='slotProps'>
                                <div class='customer-item-content'>
                                    <div class='mr-1'>
                                        <div class='values'>
                                            <div :class='slotProps.data.color.concat(` item`)'>
                                                <span>{{ slotProps.data.value }}%</span>
                                                <ProgressBar :value='slotProps.data.value'
                                                             :showValue='false'></ProgressBar>
                                                <span class='day'>{{ slotProps.data.label }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </Carousel>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CustomerService from '../service/CustomerService';
import SiteAnalyzeService from '../service/SiteAnalyzeService';
import VChart from 'vue-echarts';
import { use } from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { LineChart, PieChart } from 'echarts/charts';
import {
    GridComponent,
    DataZoomInsideComponent,
    LegendComponent,
    MarkLineComponent, MarkPointComponent,
    TitleComponent,
    ToolboxComponent,
} from 'echarts/components';

use([GridComponent, CanvasRenderer, LineChart, PieChart, TitleComponent, LegendComponent, ToolboxComponent, DataZoomInsideComponent, MarkLineComponent, MarkPointComponent]);

export default {
    components: { VChart },
    data() {
        return {
            chartTheme: 'dark',
            loadingObj: {
                overviewLoading: true,
                dataUploadRate: true,
                alarmList: true,
            },
            siteType: '',
            countAnalyze: {
                currentMonthCount: 0,
                monthChangeRate: 0,
                currentQuarterCount: 0,
                quarterChangeRate: 0,
                currentYearCount: 0,
                yearChangeRate: 0,
            },
            dataUploadAnalyze: [],
            dataUploadAnalyzeCurrentSelectedDate: null,
            carouselResponsiveOptions: [
                {
                    breakpoint: '2560px',
                    numVisible: 31,
                    numScroll: 31,
                },
                {
                    breakpoint: '1920px',
                    numVisible: 22,
                    numScroll: 10,
                },
                {
                    breakpoint: '1360px',
                    numVisible: 16,
                    numScroll: 16,
                },
                {
                    breakpoint: '1280px',
                    numVisible: 11,
                    numScroll: 11,
                },
                {
                    breakpoint: '1024px',
                    numVisible: 11,
                    numScroll: 11,
                },
                {
                    breakpoint: '768px',
                    numVisible: 7,
                    numScroll: 7,
                },
                {
                    breakpoint: '560px',
                    numVisible: 5,
                    numScroll: 5,
                },
            ],
        };
    },
    customerService: null,
    siteAnalyzeService: null,
    watch: {
        '$appState.colorScheme'() {
            this.chartTheme = this.$appState.colorScheme;
        },
    },
    created() {
        this.customerService = new CustomerService();
        this.siteAnalyzeService = new SiteAnalyzeService();
        this.chartTheme = this.$appState.colorScheme;
    },
    mounted() {
        this.$store.dispatch('UpdateRTD');
        this.$store.dispatch('UpdateRTDChart');
        this.$store.dispatch('UpdateDataUpPct').then(() => this.loadingObj.dataUploadRate = false);
        this.$store.dispatch('UpdateCountAnalyze').then(() => this.loadingObj.overviewLoading = false);
    },
    methods: {
        stateClass(state) {
            return [
                {
                    'normalState': state === '正常',
                    'failState': state !== '正常',
                },
            ];
        },
        getBadgeColor(changeRate) {
            return [
                {
                    'change-rate-negative': changeRate >= 0,
                    'change-rate-positive': changeRate < 0,
                },
            ];
            // return changeRate >= 0 ? 'change-rate-negative' : 'change-rate-positive';
        },
        abs(v) {
            return v < 0 ? -v : v;
        },
        calcChangeRate(current, previous) {
            if (current > 0 && previous > 0) {
                return ((current - previous) / previous).toFixed(1);
            }
            if (current === 0 && previous === 0) {
                return 0;
            }
            if (previous === 0) {
                return 100;
            }
            if (current === 0) {
                return -100;
            }
            return 0;
        },
    },
};
</script>

<style lang='scss' scoped>
::v-deep(.p-progressbar) {
    height: .5rem;
    background-color: #D8DADC;

    .p-progressbar-value {
        background-color: #607D8B;
    }
}

.change-rate-positive {
    background-color: #0BD18A !important;
    box-shadow: 0px 6px 20px rgba(11, 209, 138, 0.3) !important;
}

.change-rate-negative {
    background-color: #00D0DE !important;
    box-shadow: 0px 6px 20px rgba(0, 208, 222, 0.3) !important;
}

.normalState {
    font-weight: 700;
    color: #0BD18A;
}

.failState {
    font-weight: 700;
    color: #FC6161;
}

@media screen and (max-width: 960px) {
    .panel-demo {
        .toolbar-demo.card {
            overflow: auto;
        }
    }
}
</style>